label {
  display: block;
  margin-top: 10px;
}

.card-container {
  position: relative;
  /* Ensure the container is positioned relatively */
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.gm-style-iw button:focus {
  outline: 0;
}
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
 background: linear-gradient(to bottom, rgb(0, 42, 255), rgb(8, 136, 211));

}
.gm-style-iw + button {display: none;}
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece{
  background:#002DFE

}
.gm-style-iw-chr > button {
  display: none !important;
}



.gm-style-iw-chr > button {
  display: none !important;
}
.custom-marker-label {
  position: absolute;
  transform: translate(25px, -100%); /* Adjust to position the label next to the marker */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  padding: 5px 10px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
  font-size: 14px; /* Font size */
  font-weight: bold; /* Bold text */
  color: white; /* White text color */
  white-space: nowrap; /* Prevent text from wrapping */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Shadow for better visibility */
  z-index: 1000; /* Ensure it's on top */
}
.card-container.card-register {
  max-width: 600px !important;
  padding: 40px 40px;
}
.card {
  max-width: 350px;
  padding: 40px;
  background-color: #f7f7f7;
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  position: absolute;
  margin-top: auto;
  margin-right: 4%;
  right: 0;
  top: 0;
  border-radius: 2%;
}


.card-register {
  max-width: 350px;
  padding: 40px;
  background-color: #f7f7f7;
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  position: absolute;
  margin-top: auto;
  margin-right: 4%;
  right: 0;
  top: 0;
  border-radius: 2%;
}
@media (max-width: 768px) {
.apexcharts-menu-icon, .apexcharts-pan-icon, .apexcharts-reset-icon,  .apexcharts-zoom-icon, .apexcharts-zoomin-icon, .apexcharts-zoomout-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 24px;
  color: #6e8192;
  text-align: center;
  position: absolute;
  right: 1vw;
  top: 1vh;
}
.apexcharts-toolbar-custom-icon{
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 24px;
  color: #6e8192;
  text-align: center;
  position: absolute;
  right: 1vw;
  top: 0.5vh;

  margin-right: 20px;
}
.svg_icon{
  margin-left: 20px;
  z-index: 20;
}
.apexcharts-annotation-rect, .apexcharts-area-series .apexcharts-area, .apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events, .apexcharts-gridline, .apexcharts-line, .apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events, .apexcharts-point-annotation-label, .apexcharts-radar-series path, .apexcharts-radar-series polygon, .apexcharts-toolbar svg, .apexcharts-tooltip .apexcharts-marker, .apexcharts-xaxis-annotation-label, .apexcharts-yaxis-annotation-label, .apexcharts-zoom-rect {
  pointer-events: auto !important;
}
.apexcharts-menu-icon svg {
  margin-right: 10px; /* Adjust this value to the desired spacing */

}
.apexcharts-toolbar-custom-icon custom-icon-class{
  margin-right: 10px; /* Adjust this value to the desired spacing */
  z-index: 20;
}
}

.apexcharts-element-hidden{
  opacity:1 !important;
  }

.parent-container {
  width: 100vw;
  height: 100vh;
  background-image: url('./assets/background5.png'); /* Replace 'your-image-url.jpg' with the URL of your background image */
  background-size: cover; /* Cover the entire container */
  background-position: center; 
  background-repeat: no-repeat; 
}
.marker-label {
  margin-Top: 30px;
}
.card-container {
  transform: translateY(-50%) !important;
  top: 50% !important
}

@media (max-width: 768px) {
  .card-container {
    justify-content: flex-start;
    /* Change back to flex-start */
  }
}

.profile-img-card {
  width: 70%;
  height: auto;
  margin: 0 auto 0;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.appContainer {

  max-width: 100% !important;

}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: black !important;
  font-size: 22px !important;
  font-weight: 400 !important;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.swal2-styled.swal2-confirm {
  background-color: #28a745 !important;
  color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 10px 24px !important;
  margin: 0 !important;
}

.swal2-styled.swal2-deny {
  background-color: #dbdbdb !important;
  color: #555 !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 10px 24px !important;

}

.form-control:focus {
  border-color: #28a745;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #76d48c
}

@media only screen and (min-width: 1904px) {

  .imgGrid {
    background: linear-gradient(to bottom, rgb(0, 42, 255), rgb(125, 204, 250));
    height: 18.5vw
  }
}

@media only screen and (min-width: 1668px) and (max-width: 1903px) {

  .imgGrid {
    background: linear-gradient(to bottom, rgb(0, 42, 255), rgb(125, 204, 250));
    height: 308px
  }
}

@media only screen and (min-width: 1088px) and (max-width: 1667px) {

  .imgGrid {
    background: linear-gradient(to bottom, rgb(0, 42, 255), rgb(125, 204, 250));
    height: 300px
  }
}

@media only screen and (min-width: 900px) and (max-width: 1087px) {

  .imgGrid {
    background: linear-gradient(to bottom, rgb(0, 42, 255), rgb(125, 204, 250));
    height: 300px
  }
}

@media only screen and (min-width: 634px) and (max-width: 900px) {

  .imgGrid {
    background: linear-gradient(to bottom, rgb(0, 42, 255), rgb(125, 204, 250));
    height: 26vw
  }
}

@media only screen and (max-width: 634px) {

  .imgGrid {
    background: linear-gradient(to bottom, rgb(0, 42, 255), rgb(125, 204, 250));
    height: 24vw
  }
}


.custom-swal-select:hover {
  background-color: green !important;
  /* Change hover background color to green */
  color: white !important;
  /* Change hover text color to white */
}